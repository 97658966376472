<template>
  <div class="data-box">
    <van-nav-bar
        title="物品领取记录"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-head">
      请注意安全保管需交回物资，离场时须完整交回
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">物品负责人</span>
          <span class="span-name align-right">{{ personInfo.chargeName ? personInfo.chargeName : "无" }}</span>
          <a :href="'tel:' + personInfo.chargePhone" class="iconfont icon-dianhua1" v-show="personInfo.chargePhone"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-note">
          带*标记物资为需交回物资
        </div>
        <div class="body-div-table">
          <dl>
            <tr>
              <dt>物品名称</dt>
              <dt>最大领用</dt>
              <dt>单次限领</dt>
              <dt>累计领用</dt>
            </tr>
            <tr v-for="(item,index) in materialList" :key="index">
              <dd style="color: #3476FF;" @click="toMaterialDetails(item)"><span style="color: #FA5555;" v-show="item.num3">*</span>{{item.str}}</dd>
              <dd>{{item.num}}</dd>
              <dd>{{item.num2}}</dd>
              <dd>{{item.num4}}</dd>
            </tr>
          </dl>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-code">
          <vue-qr :text="codeUrl" :size="200"></vue-qr>
        </div>
        <div class="body-div-prompt align-center">
          每次领用物资时请向发放物资工作人员展示二维码<br/>
          领取相应物资，请勿自行扫码登记操作
        </div>
      </div>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import {
  getWxCode,
  showActivityMaterial
} from "@/api/index";

export default {
  name: "parMaterial",
  data() {
    return {
      personInfo: {},
      materialList: [],
      codeUrl: ""
    }
  },
  components: {
    vueQr
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getOPDetails.activitySets;
      activitySets.forEach(item => {
        if(item.relateType === 4){
          that.personInfo = item;
        }
      })
      that.getWxCode();
      that.getMaterialInfo();
    },
    getMaterialInfo(){
      let that = this;
      let params = {
        ayId : that.$store.getters.getOPDetails.id
      };
      showActivityMaterial(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.materialList = res.data.materialList;
          that.materialList.forEach(item => {
            item.num4 = Math.abs(item.num4);
          })
        })
      })
    },
    getWxCode(){
      let that = this;
      that.codeUrl = that.$store.state.baseURL + "#/parMaterial_details?id=" + that.$store.getters.getActObjId;
      /*let url = that.$store.state.baseURL + "#/parMaterial_details";
      let params = {
        app_id: that.$store.state.app_id,
        redirect_uri: that.$tool.toURLEncode(url),
        state: 1
      };
      that.codeUrl = getWxCode(params);*/
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    toMaterialDetails(item){
      let that = this;
      that.$router.push({
        name: 'orgCheck_material_details',
        query: {
          id: item.id
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>